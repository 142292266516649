<template>
  <b-container>
    <b-overlay rounded="sm" :show="show">
      <b-row class="mb-2 justify-content-end">
        <b-col md="8" lg="6">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search">
            <div class="input-group-append">
              <button class="btn btn-success" @click="searchTour()" type="button"><feather-icon
                  icon="SearchIcon" /></button>
              <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon
                  icon="XIcon" /></button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" v-for="item, index in list" :key="index">
          <b-card no-body class="overflow-hidden">
            <a :href="'/player-tournament/' + item.id" class="text-black">
            <b-card-header class="bg-info">
              <b-card-title class="text-white">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                  <line x1="4" y1="22" x2="4" y2="15"></line>
                </svg>
                {{ item.name }}{{ item.is_recurring == 1 ? ' - ' : '' }} <span class="is_recurring"
                  v-if="item.is_recurring == 1">Recurrente</span>
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <div class="mt-2">
                
           
                <span class="mr-2"><strong>Fecha :</strong> {{ formatDate(item.tournament_date) }}
                  <strong>Hora:</strong>
                  {{ item.tournament_hour ? formattedTime(item.tournament_hour) : '' }} </span>
                <b-card-text class="mb-25">
                  <strong>Nivel: </strong>{{ item.level }} <strong> - Número pistas:</strong>
                  {{ item.number_courts }}<br>
                </b-card-text>
                <b-card-text class="mb-25">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                  <strong> Confirmado: </strong>{{ confirmed_tour && confirmed_tour == item.id ? 'Sí' : (item.players[0].pivot.assistance_confirmation == 0 ? 'No' : 'Sí') }}                   
                </b-card-text>
                <div class="text-center">
                  <p v-if="item.winners"><img src="/winners.png" alt="Pareja ganadora" width="60" /></p>
                  <p v-if="item.winners">  Pareja ganadora</p>
                  <p v-if="item.winners"><strong>{{ item.winners }}</strong> </p>
                </div>
              
              
              </div>
            </b-card-body>

          </a>
          <b-button variant="success" @click="confirmParticipation(item.id)" v-if="item.players[0].pivot.assistance_confirmation == 0 && confirmed_tour !== item.id" block>Confirmar participación</b-button>
          </b-card>
        </b-col>
        <div class="">
          <h5 v-if="list.length == 0" class="text-danger h5">¡NO HAS PARTICIPADO EN TORNEOS AÚN!</h5>
        </div>
        <b-col lg="12">        
          <a :href="'/player-historial/'+user.player.id" class="btn btn-warning d-block" style="display:block!important">
              Histórico
          </a>
        </b-col>

      </b-row>
    </b-overlay>
  
  </b-container>

</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
import moment from 'moment';
import vSelect from 'vue-select'
export default {
  props: ['type'],
  watch: {
    type: function () {
      this.show = true;
      this.search = '',
      this.list = [];
    }
  },
  components: { vSelect, VBModal, BForm, BFormInput, BFormTextarea, BContainer, BCardHeader, BCard, BRow, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay },
  data() {
    return {
      show: false,
      list: [],
      match_id: null,
      winner_couple: null,
      lose_couple: null,
      result1: null,
      result2: null,
      couples: [], 
      confirmed_tour: null,
      search: '',

    }
  },
  created() {
    this.show = true;
    this.$http.get("/api/getPlayerTournaments?id=" + this.user.player.id).then((response) => {
      this.list = response.data;
      this.show = false;
    });
  },
  methods: {
    confirmParticipation(tournament){
      this.$bvModal.msgBoxConfirm('¿Estás seguro/a de confirmar participación?', {size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
          this.$http.post("/api/confirmParticipation",{'tournament_id': tournament,'player_id': this.user.player.id}).then((response) => {
            this.show = false;
            this.confirmed_tour = tournament;       
            this.$bvToast.toast('¡¡Confirmación realizada con éxito!!', { variant: 'success', noCloseButton: true });   
          });
        }
      })
    },
    // inserResultLose(match){
    //   this.match_id = match
    //   this.$bvModal.msgBoxConfirm('¿Estás seguro/a de informar de partido perdido? Se procederá a la creación de nuevo partido bajando de pista', {size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',hideHeaderClose: false,centered: true})
    //   .then(value => {
    //     if(value) {
    //       this.$http.post("/api/insertResultMatch",{'id': this.match_id,'player_id': this.user.player.id, 'type': 'lose'}).then((response) => {
    //         this.show = false;
    //         this.$bvModal.hide('insert-result-modal');
    //         this.$bvToast.toast('Resultados introducidos correctamente', { variant: 'success', noCloseButton: true });   
    //       });
    //     }
    //   })
    // },
    // openInsertResultModal(match) {
    //   this.match_id = match;
    //   this.$bvModal.show('insert-result-modal');
    // },
    getOptionKey(option) {
      return option ? option.id : null;
    },
    
    // insertResultMatch() {
    //   this.$http.post("/api/insertResultMatch",{'id': this.match_id,'player_id': this.user.player.id, 'type': 'winner', 'result1': this.result1, 'result2': this.result2}).then((response) => {
    //     this.show = false;
    //     this.$bvModal.hide('insert-result-modal');
    //     window.location.reload();
    //   });
    //   this.$bvToast.toast('Resultados introducidos correctamente', { variant: 'success', noCloseButton: true });   
    // },
    
    searchTour() {
      this.show = true;     
      this.$http.get("/api/getPlayerTournaments?search="+this.search+'&id='+this.user.player.id).then((response) => {
        this.list = response.data;
        this.show = false;
      });
    },
    searchReset() {
      this.search = '';
      this.searchTour();
    },


    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD-MM-YYYY');
    },
  }, computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}

.is_recurring {
  background-color: rgb(233, 196, 31);
  color: black;
  padding: 0px 2px;
}

.bg-no-conf {
  background-color: rgb(231, 90, 109);
  padding: 2px;
  color: white;
}
.text-black {
  color: black !important;
}

.bg-yes-conf {
  background-color: rgb(90, 231, 114);
  padding: 2px 4px;
  color: rgb(0, 0, 0);
}

#insert-result-modal {
  margin-top: 40px;
}

.d-block{
  display: block!important;
}
</style>